import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import Firebase auth
import { app } from "./firebaseConfig";
import $ from "jquery";

const auth = getAuth(app);

// -------------------------
// WEBHOOK FUNCTION
// -------------------------

async function handleFormSubmission(event) {
  event.preventDefault(); // Prevent the form from reloading the page

  const gDocURL = $("#gDocURL").val();
  const baseResumeName = $("#baseResumeName").val();

  // Get the current authenticated user
  const currentUser = auth.currentUser;

  // Ensure the user is authenticated before proceeding
  if (!currentUser) {
    console.error("User is not authenticated.");
    return;
  }

  const userData = {
    gDocURL: gDocURL,
    baseResumeName: baseResumeName,
    userId: currentUser.uid, // Firebase UID
    userEmail: currentUser.email, // Firebase Email
    processCount: 1, // Initial process count
  };

  try {
    const response = await fetch(process.env.WEBHOOK_RESUME_SETUP, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    // Check if the response is okay
    if (!response.ok) {
      throw new Error(`Webhook request failed with status ${response.status}`);
    }

    const data = await response.json();

    // Log the full response data to the console
    console.log("Webhook response data:", data);
    console.log("isPublic:", data.isPublic);

    // Display success or error based on the response
    const statusDiv = $("#setupResumeStatus");

    if (data.isPublic === true) {
      statusDiv.text(
        "Resume submitted. (Refresh dashboard page in 30-60s to view.)"
      );
    } else if (data.isPublic === false) {
      statusDiv
        .text(
          "Error: submitted link is not publicly viewable. Update the document's sharing/visibility settings."
        )
        .css("color", "red");
    } else {
      statusDiv.text("Error. Resume not submitted.").css("color", "red");
    }
  } catch (error) {
    console.error("Error during form submission:", error);
    $("#setupResumeStatus")
      .text("Error. Resume not submitted.")
      .css("color", "red");
  }
}

// -------------------------
// MODAL & EVENT LISTENERS
// -------------------------

// Function to create and display the modal and its form
function setupResumeDisplay() {
  const modalBackdrop = $('<div id="modalBackdrop"></div>');
  const setupResumeFormContainer = $(`
    <div id="setupResumeFormContainer">
      <h2>Setup Resume</h2>
      <form id="setupResumeInputGDocForm">
        <label for="baseResumeName">Base Resume Name</label><br />
        <input type="text" id="baseResumeName" name="baseResumeName" value="(default)" style="min-width: 300px;" /><br /><br />
        <label for="gDocURL">Paste Google Doc URL</label><br />
        <input type="url" id="gDocURL" name="gDocURL" style="min-width: 300px;" /><br /><br />
        <button type="submit" class="btn">Import Resume</button>
      </form>
      <div id="setupResumeStatus" class="setup-resume-form-status"></div>
    </div>
  `);

  // Append modal to body
  $("body").append(modalBackdrop).append(setupResumeFormContainer);

  // Hide modal initially
  $("#modalBackdrop").hide();
  $("#setupResumeFormContainer").hide();
}

// Function to setup event listeners for modal display and form submission
function setupEventListeners() {
  // Show modal when "Setup Resume" button is clicked
  $(document).on("click", "#btn-setup-resume", function () {
    $("#modalBackdrop").show();
    $("#setupResumeFormContainer").fadeIn();
  });

  // Hide modal when backdrop is clicked
  $("#modalBackdrop").click(function () {
    $("#modalBackdrop").hide();
    $("#setupResumeFormContainer").fadeOut();
  });

  // Form submission handler
  $(document).on("submit", "#setupResumeInputGDocForm", handleFormSubmission);
}

// Function to create the "Setup Resume" button dynamically
function createSetupResumeButton() {
  const button = $("<button>")
    .attr("id", "btn-setup-resume")
    .addClass("btn btn-setup-resume")
    .text("Setup Base Resume");

  // Append the button to the placeholder div
  $("#userSetupBaseResumePlaceholder").append(button);

  $("#userSetupBaseResumePlaceholder").append($("<hr>"));
}

// -------------------------
// AUTH STATE LISTENER
// -------------------------

onAuthStateChanged(auth, (user) => {
  const setupResumePlaceholder = document.getElementById(
    "userSetupBaseResumePlaceholder"
  );

  if (user) {
    // User is logged in, create and display the Setup Resume button
    console.log(`User is logged in as: ${user.email}`);
    createSetupResumeButton();
    setupEventListeners(); // Attach event listeners once the button is created
  } else {
    // No user is logged in, hide the placeholder
    console.log("No user is logged in.");
    setupResumePlaceholder.style.display = "none";
  }
});

// -------------------------
// LISTENER INITIALIZATION
// -------------------------
document.addEventListener("DOMContentLoaded", () => {
  // Initialize the modal
  setupResumeDisplay();
});
